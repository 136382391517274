






































































import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import Api from "@/api/smartForm";
import { Toast } from "vant";
import 'vant/lib/toast/style';


export default defineComponent({
  name: "WillingSignUpSuccess",
  components: {},
  setup(props, context) {
    const state = reactive<{
      msg: any;
      qrCode: string;
      attachments: Array<any>;
      bgInfo: any;
      pageSort: Array<string>;
    }>({
      msg: null,
      qrCode: "",
      attachments: [],
      bgInfo: null,
      pageSort: ["msg", "image", "attachments"]
    });
    const Methods = {
      getBgStyle(bgInfo: any): any {
        const bgcss: any = { height: "100vh", "box-sizing": "border-box" };
        if (bgInfo.type === "color") {
          bgcss.background = bgInfo.color;
        } else {
          bgcss["background-image"] = `url(${bgInfo.image})`;
        }
        return bgcss;
      }
    };

    const stateOld = reactive<{
      page_info: any;
      guide_text: string;
    }>({
      page_info: null,
      guide_text: ""
    });
    const MethodsOld = {
      applyHtmlStyle(content: string) {
        if (state.bgInfo) return "";
        content = content
          .replace(/\n$/g, "\n\n")
          .replace(/[\r\n]/g, "<br>")
          .replace(/\s/g, "&nbsp;");
        return content;
      }
    };

    onMounted(() => {

      if(context.root.$route.query.msg){
        Toast({
          message: String(context.root.$route.query.msg),
          position: "top",
        });
      }
      Api.getSmartFormSuccess({
        corp_id: context.root.$route.query.corp_id,
        form_order_id: context.root.$route.query.order_id
      }).then(res => {
        const data: any = res.data;
        state.bgInfo = data.bg_info;
        state.msg = data.msg;
        state.qrCode = data.qr_code?.url;
        state.attachments = data.attachments;
        state.pageSort = data.page_sort || state.pageSort;

        stateOld.page_info = res.data;
        stateOld.guide_text = MethodsOld.applyHtmlStyle(res.data.guide_message?.text);
      });
    });
    return {
      ...toRefs(state),
      ...Methods,
      ...toRefs(stateOld),
      ...MethodsOld
    };
  }
});
