var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.check && _vm.page_info && !_vm.isOverTime
      ? _c(
          "div",
          {
            staticClass: "home",
            style: { pointerEvents: _vm.isWeixin ? "" : "none" }
          },
          [
            _c("img", {
              attrs: {
                src: _vm.page_info.form_top_img
                  ? _vm.page_info.form_top_img.image_url
                  : "https://wlmvp-cos.weiling.cn/adlandingpage/businessMeeting/SignUp/default-sign-up-top-image.png",
                alt: "sign up page top image"
              }
            }),
            _c(
              "form",
              { staticClass: "form_wrap" },
              _vm._l(_vm.page_info.metadata_list, function(item, index) {
                return _c("div", { key: index }, [
                  _c(
                    "div",
                    {
                      ref: "list_item",
                      refInFor: true,
                      staticClass: "form_item"
                    },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "label",
                          attrs: { for: item.chinese_name }
                        },
                        [
                          _c("p", { staticClass: "label_name" }, [
                            _vm._v(" " + _vm._s(item.chinese_name) + " "),
                            !item.allow_null
                              ? _c("span", { staticClass: "required" }, [
                                  _vm._v("*")
                                ])
                              : _vm._e(),
                            item.data_type == "5" || item.data_type == "6"
                              ? _c("span", { staticClass: "check_box_title" }, [
                                  _vm._v(
                                    _vm._s(
                                      item.data_type == "5"
                                        ? "（单选）"
                                        : "（多选)"
                                    )
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      ),
                      item.data_type == "1"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.value,
                                expression: "item.value"
                              }
                            ],
                            class: item.isWarning
                              ? "text_input warning_border"
                              : "text_input normal_border",
                            attrs: {
                              type: "text",
                              placeholder: item.col_describe
                            },
                            domProps: { value: item.value },
                            on: {
                              change: function($event) {
                                return _vm.onChange(
                                  item.col_name,
                                  item.value,
                                  index
                                )
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "value", $event.target.value)
                              }
                            }
                          })
                        : _vm._e(),
                      item.data_type == "3"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.value,
                                expression: "item.value"
                              }
                            ],
                            class: item.isWarning
                              ? "text_input warning_border"
                              : "text_input normal_border",
                            attrs: {
                              type: "tel",
                              placeholder: item.col_describe
                            },
                            domProps: { value: item.value },
                            on: {
                              change: function($event) {
                                return _vm.onChange(
                                  item.col_name,
                                  item.value,
                                  index
                                )
                              },
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(item, "value", $event.target.value)
                              }
                            }
                          })
                        : _vm._e(),
                      item.data_type == "4"
                        ? _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: item.value,
                                  expression: "item.value"
                                }
                              ],
                              class: item.isWarning
                                ? "select_input warning_border"
                                : "select_input normal_border",
                              attrs: { name: item.chinese_name },
                              on: {
                                change: [
                                  function($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call($event.target.options, function(o) {
                                        return o.selected
                                      })
                                      .map(function(o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      item,
                                      "value",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                  function($event) {
                                    return _vm.onChange(
                                      item.col_name,
                                      item.value,
                                      index
                                    )
                                  }
                                ]
                              }
                            },
                            [
                              _c(
                                "option",
                                {
                                  attrs: {
                                    value: "",
                                    disabled: "",
                                    selected: "",
                                    hidden: ""
                                  }
                                },
                                [
                                  _vm._v(
                                    "-- " + _vm._s(item.col_describe) + " --"
                                  )
                                ]
                              ),
                              _vm._l(JSON.parse(item.col_remark), function(
                                option,
                                index
                              ) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: { value: option.key }
                                  },
                                  [_vm._v(_vm._s(option.value))]
                                )
                              })
                            ],
                            2
                          )
                        : _vm._e(),
                      item.data_type == "5"
                        ? _c(
                            "div",
                            { staticClass: "radio_input_wrap" },
                            [
                              _c(
                                "van-radio-group",
                                {
                                  on: {
                                    change: function($event) {
                                      return _vm.onCheckboxChange(index)
                                    }
                                  },
                                  model: {
                                    value: _vm.submit_data[item.col_name],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.submit_data,
                                        item.col_name,
                                        $$v
                                      )
                                    },
                                    expression: "submit_data[item.col_name]"
                                  }
                                },
                                _vm._l(JSON.parse(item.col_remark), function(
                                  option,
                                  i
                                ) {
                                  return _c(
                                    "van-radio",
                                    {
                                      key: i,
                                      attrs: {
                                        name: option.key,
                                        "label-position": "left"
                                      }
                                    },
                                    [_vm._v(_vm._s(option.value))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.data_type == "6"
                        ? _c(
                            "div",
                            { staticClass: "check_input_wrap" },
                            [
                              _c(
                                "van-checkbox-group",
                                {
                                  on: {
                                    change: function($event) {
                                      return _vm.onCheckboxChange(index)
                                    }
                                  },
                                  model: {
                                    value: _vm.submit_data[item.col_name],
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.submit_data,
                                        item.col_name,
                                        $$v
                                      )
                                    },
                                    expression: "submit_data[item.col_name]"
                                  }
                                },
                                _vm._l(JSON.parse(item.col_remark), function(
                                  option,
                                  i
                                ) {
                                  return _c(
                                    "van-checkbox",
                                    {
                                      key: i,
                                      attrs: {
                                        name: option.key,
                                        "label-position": "left",
                                        "icon-size": "20"
                                      }
                                    },
                                    [_vm._v(_vm._s(option.value))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      item.isWarning
                        ? _c("div", { staticClass: "required_text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.data_type == "4" || item.data_type == "5"
                                    ? "请选择"
                                    : "请输入"
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  )
                ])
              }),
              0
            ),
            _c(
              "button",
              {
                staticClass: "submit_button",
                style: _vm.button_style,
                on: { click: _vm.submitForm }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.page_info.button_vo.button_text
                        ? _vm.page_info.button_vo.button_text
                        : ""
                    ) +
                    " "
                )
              ]
            ),
            _c("Statement", {
              attrs: { companyName: _vm.company_name },
              model: {
                value: _vm.statementChecked,
                callback: function($$v) {
                  _vm.statementChecked = $$v
                },
                expression: "statementChecked"
              }
            }),
            _vm.show_tip
              ? _c("div", { staticClass: "tip_message" }, [
                  _vm._v(_vm._s(_vm.tip_message))
                ])
              : _vm._e(),
            _vm.delayPop
              ? _c("div", { staticClass: "error_message2" }, [
                  _vm._v("请使用个人微信报名")
                ])
              : _vm._e(),
            !_vm.isWeixin ? _c("div", { staticClass: "layer_mask" }) : _vm._e(),
            _vm.is_show_snapshot_notice
              ? _c("div", [_c("SnapshotNotice")], 1)
              : _vm._e()
          ],
          1
        )
      : _vm.isOverTime
      ? _c("div", [_vm._m(0)])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "default-wrap" }, [
      _c("p", [_vm._v("您来晚啦，报名已截止")]),
      _c("img", {
        attrs: {
          src: require("@/assets/images/expired_default.png"),
          alt: "default"
        }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }