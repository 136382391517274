





import { defineComponent, reactive, toRefs } from "@vue/composition-api";
export default defineComponent({
  name: "WorktableErpPreviewPage",
  components: {},
  setup() {
    const state = reactive<{
      content: string;
    }>({
      content: "Hello world"
    });
    return {
      ...toRefs(state)
    };
  }
});
