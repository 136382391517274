var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.check && _vm.isWeixin
    ? _c("div", { staticClass: "home safe-area-inset-bottom" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/check_in.png"),
            alt: "Check In"
          }
        }),
        _c("div", { staticClass: "title" }, [_vm._v("签到成功！")]),
        _c("div", { staticClass: "content" }, [
          _vm._v(
            " 感谢您的到来，请前往会场参与企业微信线下闭门沙龙，签到处可领取伴手礼一份哦。 "
          )
        ])
      ])
    : !_vm.isWeixin
    ? _c("div", { staticClass: "home1 safe-area-inset-bottom" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/warning.png"),
            alt: "Check In"
          }
        }),
        _c("div", { staticClass: "warning_title" }, [
          _vm._v("请使用个人微信扫码签到")
        ])
      ])
    : _c("div")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }