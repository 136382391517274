const vue_api = process.env.VUE_APP_API;
import Api from "@/api/api";
const useWebsiteTrack: any = {
  corp_id: "", // 企业ID
  referer: document.referrer,
  title: document.title,
  url: document.URL,
  type: 1, // 1-首次 2-后面
  interval: 5, //调用监测接口的间隔，单位毫秒
  max_stay_time: 300, //最长停留时间，单位秒
  event_id: "", //上一级事件ID，如果当前页面是分享过来则需带上˝
  duration: 0, // 停留时间
  onWebsiteTrack() {
    const params: any = {
      referer: this.referer,
      title: this.title,
      url: this.url,
      type: 1,
      name: ""
    };
    if (this.event_id) {
      (params.event_id = this.event_id), (params.type = 2);
      params.duration = this.duration;
    }
    return Api.websiteTrack(`${vue_api}/api/website/track`, params).then(
      (res: any) => {
        this.event_id = res.event_id;
      }
    );
  },
  init(corp_id: any, name = "") {
    this.name = name;
    this.title = document.title;
    this.corp_id = corp_id;
    this.referer = document.referrer;
    this.url = document.URL;
    this.onWebsiteTrack().then((res1: any) => {
      let timer = setInterval(() => {
        if (this.duration >= this.max_stay_time) {
          clearInterval(timer);
        }
        this.duration += 5;
        this.onWebsiteTrack();
      }, this.interval * 1000);
    });
  },
  getEventId() {
    return this.event_id;
  }
};
export default useWebsiteTrack;
