<template>
  <div>
    <div v-if="check && page_info && !isOverTime" class="home" :style="{ pointerEvents: isWeixin ? '' : 'none' }">
      <img
        :src="
          page_info.form_top_img
            ? page_info.form_top_img.image_url
            : 'https://wlmvp-cos.weiling.cn/adlandingpage/businessMeeting/SignUp/default-sign-up-top-image.png'
        "
        alt="sign up page top image"
      />
      <!-- <form>
        <component :is="type[item.type]"> </component>
      </form> -->
      <form class="form_wrap">
        <div v-for="(item, index) in page_info.metadata_list" v-bind:key="index">
          <div class="form_item" ref="list_item">
            <!-- 名称 -->
            <label class="label" :for="item.chinese_name"
              ><p class="label_name">
                {{ item.chinese_name }}
                <span class="required" v-if="!item.allow_null">*</span>
                <span class="check_box_title" v-if="item.data_type == '5' || item.data_type == '6'">{{
                  item.data_type == "5" ? "（单选）" : "（多选)"
                }}</span>
              </p>
            </label>
            <input
              :class="item.isWarning ? 'text_input warning_border' : 'text_input normal_border'"
              type="text"
              :placeholder="item.col_describe"
              v-model="item.value"
              v-if="item.data_type == '1'"
              @change="onChange(item.col_name, item.value, index)"
            />
            <input
              :class="item.isWarning ? 'text_input warning_border' : 'text_input normal_border'"
              type="tel"
              :placeholder="item.col_describe"
              v-model="item.value"
              v-if="item.data_type == '3'"
              @change="onChange(item.col_name, item.value, index)"
            />
            <select
              :class="item.isWarning ? 'select_input warning_border' : 'select_input normal_border'"
              :name="item.chinese_name"
              v-if="item.data_type == '4'"
              v-model="item.value"
              @change="onChange(item.col_name, item.value, index)"
            >
              <option value="" disabled selected hidden>-- {{ item.col_describe }} --</option>
              <option
                v-for="(option, index) in JSON.parse(item.col_remark)"
                v-bind:key="index"
                :value="option.key"
                >{{ option.value }}</option
              >
            </select>
            <!-- 单选 -->
            <div class="radio_input_wrap" v-if="item.data_type == '5'">
              <van-radio-group v-model="submit_data[item.col_name]" @change="onCheckboxChange(index)">
                <van-radio
                  v-for="(option, i) in JSON.parse(item.col_remark)"
                  :key="i"
                  :name="option.key"
                  label-position="left"
                  >{{ option.value }}</van-radio
                >
              </van-radio-group>
            </div>
            <!-- 多选 -->
            <div class="check_input_wrap" v-if="item.data_type == '6'">
              <van-checkbox-group
                v-model="submit_data[item.col_name]"
                @change="onCheckboxChange(index)"
              >
                <van-checkbox
                  :name="option.key"
                  label-position="left"
                  icon-size="20"
                  v-for="(option, i) in JSON.parse(item.col_remark)"
                  :key="i"
                  >{{ option.value }}</van-checkbox
                >
              </van-checkbox-group>
            </div>
            <div v-if="item.isWarning" class="required_text">
              {{ item.data_type == "4" || item.data_type == "5" ? "请选择" : "请输入" }}
            </div>
          </div>

          <!-- <div>
            <label :for="name">{{ name }}</label>
            <input v-model="inputVal" v-bind="item" v-on="item.on" />
          </div> -->
        </div>
      </form>

      <button class="submit_button" @click="submitForm" :style="button_style">
        {{ page_info.button_vo.button_text ? page_info.button_vo.button_text : "" }}
      </button>
      <!-- 声明 -->
      <Statement v-model="statementChecked" :companyName="company_name" />

      <div v-if="show_tip" class="tip_message">{{ tip_message }}</div>

      <div v-if="delayPop" class="error_message2">请使用个人微信报名</div>
      <div v-if="!isWeixin" class="layer_mask"></div>
      <div v-if="is_show_snapshot_notice">
        <SnapshotNotice />
      </div>
    </div>
    <div v-else-if="isOverTime">
      <div class="default-wrap">
        <p>您来晚啦，报名已截止</p>
        <img src="@/assets/images/expired_default.png" alt="default">
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import useAuthcheck from "@/hooks/useAuthcheck";
import Api from "@/api/businessMeeting";
import { isMobile } from "@/utils/utils";
import Statement from "@/views/smartForm/Statement/index.vue";
import { getSignData } from "@/api/common";
import Vue from "vue";
import SnapshotNotice from "@/components/SnapshotNotice.vue";
import useSnapshot from "@/hooks/useSnapshot";

const overTimeList = {
  "1663004771893768192":"2023/06/19 21:00:00",//有赞
  "1661318515421999104":"2023/06/08 20:00:00", // 金士达
  "1632710122419650560":"2023/03/21 23:00:00", // 金士达
  "1637729948988989440":"2023/03/21 17:30:00", // 测试
  "1637995651679756288":"2023/03/21 10:20:00",// 测试2
  "1638023789310746624":"2023/03/21 10:20:00", //测试3
  "1638026874514866176":"2023/03/21 14:00:00",//预发测试
  "1666732726578946048":"2023/06/08 17:15:00",//测试4
}

export default defineComponent({
  name: "WillingSignUp",
  components: {
    Statement,
    SnapshotNotice
  },
  setup(props, context) {
    const { is_show_snapshot_notice, snapshotInit } = useSnapshot();
    const state = reactive({
      // type: {
      //   1: "wlInput",
      //   2: "wlRadio",
      //   3: "wlDropdown"
      // },
      // mockData: [
      //   {
      //     type: 1,
      //     title: "First Input"
      //   },
      //   {
      //     type: 2,
      //     title: "Second Radio"
      //   },
      //   {
      //     type: 3,
      //     title: "Third Dropdown"
      //   }
      // ]
      statementChecked: true, //授权状态
      button_style: "",
      page_info: "",
      required_col: [],
      submit_data: {},
      check: false,
      show_tip: false,
      tip_message: "",
      ua: navigator.userAgent.toLowerCase(),
      isWXWork: false,
      isWeixin: false,
      delayPop: false,
      company_name: "",
      isOverTime: false, // 是否截止
    });
    const Methods = {
      submitForm: () => {
        let isError = false;
        let errorCount = 0;
        let errorMessage = "";

        state.required_col.forEach(col => {
          if (col.col_name != "mobile") {
            if (!state.submit_data[col.col_name]) {
              isError = true;
              errorMessage = `请输入${col.chinese_name}`;
              errorCount++;
              state.page_info.metadata_list[col.index].isWarning = true;
            }
          }

          // 手机号格式验证
          if (col.col_name == "mobile") {
            if (!state.submit_data[col.col_name]) {
              isError = true;
              errorMessage = `请输入${col.chinese_name}`;
              errorCount++;
              state.page_info.metadata_list[col.index].isWarning = true;
            } else if (!isMobile(state.submit_data[col.col_name])) {
              isError = true;
              errorMessage = `请输入正确的手机号`;
              errorCount++;
              state.page_info.metadata_list[col.index].isWarning = true;
            }
          }
        });

        if (isError) {
          errorCount > 1
            ? Methods.showTipMessage("请输入必填信息")
            : Methods.showTipMessage(errorMessage);
        } else if (!state.statementChecked) {
          Methods.showTipMessage(`请阅读并勾选《个人信息授权与保护声明》`);
        } else {
          Api.postBusMtgFormData(
            {
              ...state.submit_data,
              url: document.URL,
              wl_meeting_id: context.root.$route.query.wl_meeting_id,
              wl_meeting_bind_id: context.root.$route.query.wl_meeting_bind_id,
              form_order_id: state.page_info.order_id
            },
            context.root.$route.query.corp_id
          ).then(res => {
            if (res.code != 200) {
              Methods.showTipMessage("报名失败，请重试！");
            } else {
              if (res.msg == "session failed") {
                Methods.showTipMessage("请授权后重试！");
              } else {
                // 有赞，活动营销未产品化，需要支持6/20的大活动，报名后自定义跳转到有赞的抽奖系统
                if(context.root.$route.query.wl_meeting_id === '1663004771893768192'){
                  location.href = 'https://shop192169.youzan.com/wscump/lottery/scene?alias=UTXNbpE14p8X&kdtId=1&shopAutoEnter=1';
                  return;
                }
                // 如果地址上有wl_page_id 那么说明是来补充信息来的 报名成功直接去签到去
                if(context.root.$route.query.wl_page_id){
                  location.href = `${location.origin}/bus-mtg-check-in?wl_page_id=${context.root.$route.query.wl_page_id}&corp_id=${context.root.$route.query.corp_id}`;
                }else{
                  location.href = `${location.origin}/bus-mtg-sign-up-success?order_id=${res.data.order_id}&corp_id=${context.root.$route.query.corp_id}`;
                }
              }
            }
          });
        }
        // console.log("index", context.refs.list_item[0]);
      },
      onChange: (col, val, index) => {
        context.root.$set(state.page_info.metadata_list, index, {
          ...state.page_info.metadata_list[index],
          isWarning: false
        });
        state.submit_data[col] = val;
      },
      onCheckboxChange: index => {
        context.root.$set(state.page_info.metadata_list, index, {
          ...state.page_info.metadata_list[index],
          isWarning: false
        });
      },
      showTipMessage: msg => {
        state.show_tip = true;
        state.tip_message = msg;
        setTimeout(() => {
          state.show_tip = false;
          state.tip_message = "";
        }, 2500);
      }
    };
    const checkIsOverTime = ()=>{
      // des:有wl_page_id表示是从签到过来的 
      if(!context.root.$route.query.wl_page_id && overTimeList[context.root.$route.query.wl_meeting_id]){
        const nowTime = Date.now(); //当前时间戳
        state.isOverTime = new Date(overTimeList[context.root.$route.query.wl_meeting_id]).getTime() < nowTime; 
      }
      return state.isOverTime
    }

    const init = async () => {
      if(context.root.$route.query.wl_page_id){
        context.root.$notify({
          message: "扫码成功，请填写表单完成签到~",
          color: "#494B4D",
          background: "#FFF5ED",
          duration: 5000
        });
      }

      const signData = await getSignData();
      Vue.prototype.app_id = signData.app_id;
      snapshotInit(signData.app_id)

      Api.getBusMtgSignPageData({
        corp_id: context.root.$route.query.corp_id,
        wl_meeting_id: context.root.$route.query.wl_meeting_id,
        wl_meeting_bind_id: context.root.$route.query.wl_meeting_bind_id
      }).then(res => {
        state.page_info = res.data;
        state.button_style = {
          backgroundColor: res.data.button_vo.css.background_color,
          color: res.data.button_vo.css.font_color
        };
        state.page_info.metadata_list.forEach((item, index) => {
          if (item.allow_null == false) {
            state.required_col.push({ index, ...item });
          }
          item.value = "";
          item.isWarning = false;
        });
        state.company_name = res.data.corp.corp_name;
      });
    };

    onMounted(() => {
      if(!checkIsOverTime()){
        useAuthcheck().then(res => {
          if (res === "uncheck") {
            state.check = false;
          } else {
            state.check = true;
            init();
          }
        });
      }
    });

    state.isWXWork = state.ua.match(/wxwork/i) == "wxwork";
    state.isWeixin = !state.isWXWork && state.ua.match(/micromessenger/i) == "micromessenger";

    if (!state.isWeixin) {
      setTimeout(() => {
        state.delayPop = true;
      }, 200);
    }

    return {
      ...toRefs(state),
      ...Methods,
      is_show_snapshot_notice
    };
  }
});
</script>

<style lang="scss" scoped>
.home {
  background-color: #f6f7f8;
  min-height: 100vh;
  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .form_wrap {
    width: 76%;
    margin: 4%;
    padding: 5% 8%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: j(22);
    box-shadow: j(0) j(4) j(16) rgba(0, 0, 0, 0.08);
    .form_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .label {
        width: 100%;
        // height: j(24);
        margin: j(16) j(0) j(8);
        display: flex;
        align-items: center;
        font-size: j(16);
        font-weight: 600;
        .label_name {
          font-size: j(16);
          font-weight: 600;
          // margin: j(16) j(0) j(8);
          // margin-bottom:j(20);
          // margin-top:j(16);
        }
        .required {
          color: rgb(255, 106, 83);
        }
        .check_box_title {
          font-weight: 600;
          font-size: j(14);
        }
      }
      .text_input {
        height: j(38);
        padding: 0px 0px 0px 5%;
        // border: j(1) solid rgb(238, 238, 238);
        border-radius: j(6);
        font-size: j(14);
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        //  margin-bottom:j(20);
      }
      // .radio_input_wrap {
      //   height: j(40);
      //   display: flex;
      //   align-items: center;
      //   .radio_input {
      //     vertical-align: middle;
      //     height: j(24);
      //     &:nth-child(even) {
      //       margin-left: 30%;
      //     }
      //   }
      // }
      .radio_input_wrap {
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 6px;
        // margin-bottom: j(20);
        .van-radio {
          justify-content: space-between;
          border-bottom: 1px solid #eeeeee;
          height: 38px;
          padding: 0 j(14) 0 j(10);
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .check_input_wrap {
        background: #ffffff;
        border: 1px solid #eeeeee;
        box-sizing: border-box;
        border-radius: 6px;
        // margin-bottom: j(20);
        .van-checkbox {
          justify-content: space-between;
          border-bottom: 1px solid #eeeeee;
          height: 38px;
          padding: 0 j(14) 0 j(10);
          &:last-child {
            border-bottom: none;
          }
        }
      }
      .select_input {
        height: j(40);
        padding: 0px 0px 0px 5%;
        // border: j(1) solid #eeeeee;
        border-radius: j(6);
        font-size: j(14);
        background-color: #fff;
        color: #757575;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
      }
    }
  }
  .submit_button {
    width: 92%;
    margin: j(12) 4%;
    height: j(44);
    border-radius: j(22);
    border: 0px;
    background-color: #0066fe;
    color: rgb(255, 255, 255);
    font-size: j(16);
    font-weight: 600;
  }
  .tip_message {
    position: fixed;
    max-width: j(300);
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: j(20);
    padding: j(12);
    border-radius: j(5);
    background-color: rgba(0, 0, 0, 0.95);
    color: #ffffff;
    z-index: 10;
  }
  .required_text {
    position: absolute;
    // display: none;
    color: red;
    right: j(12);
    bottom: j(10);
    font-size: j(14);
  }
  .warning_border {
    border: j(1) solid red;
  }
  .normal_border {
    border: j(1) solid rgb(238, 238, 238);
  }
  .error_message2 {
    position: fixed;
    max-width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: j(18);
    padding: 4%;
    border-radius: j(10);
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    z-index: 10;
    text-align: center;
  }
  .layer_mask {
    position: fixed;
    top: 0;
    pointer-events: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.default-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30vh;
  > p{
    color: #494B4D;
    font-size: j(14);
  }
  > img{
    width: j(220);
  }
}
</style>
