var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.check
    ? _c(
        "div",
        {
          staticClass: "home safe-area-inset-bottom",
          style: { pointerEvents: _vm.isWeixin ? "" : "none" }
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/sign_up_page_01.png"),
              alt: "sign up page first part"
            }
          }),
          _c("div", { staticClass: "form_wrap" }, [
            _c("div", { staticClass: "form_item" }, [
              _vm._m(0),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user_name,
                    expression: "user_name"
                  }
                ],
                staticClass: "text_input",
                attrs: { type: "text", placeholder: "请输入姓名" },
                domProps: { value: _vm.user_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.user_name = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form_item" }, [
              _vm._m(1),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.mobile,
                    expression: "mobile"
                  }
                ],
                staticClass: "text_input",
                attrs: {
                  type: "text",
                  placeholder: "请输入电话",
                  maxlength: "11"
                },
                domProps: { value: _vm.mobile },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.mobile = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form_item" }, [
              _vm._m(2),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.corp_name,
                    expression: "corp_name"
                  }
                ],
                staticClass: "text_input",
                attrs: { type: "text", placeholder: "请输入公司名称" },
                domProps: { value: _vm.corp_name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.corp_name = $event.target.value
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form_item" }, [
              _vm._m(3),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.position,
                    expression: "position"
                  }
                ],
                staticClass: "text_input",
                attrs: { type: "text", placeholder: "请输入职位信息" },
                domProps: { value: _vm.position },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.position = $event.target.value
                  }
                }
              })
            ]),
            _c(
              "button",
              { staticClass: "submit_button", on: { click: _vm.onSubmit } },
              [_vm._v("提交报名")]
            )
          ]),
          _c("img", {
            attrs: {
              src: require("@/assets/images/sign_up_page_02.jpg"),
              alt: "sign up page second part"
            }
          }),
          _vm.on_error
            ? _c("div", { staticClass: "error_message" }, [
                _vm._v(_vm._s(_vm.error_message))
              ])
            : _vm._e(),
          _vm.delayPop
            ? _c("div", { staticClass: "error_message2" }, [
                _vm._v("请使用个人微信"),
                _c("br"),
                _vm._v("扫码报名")
              ])
            : _vm._e(),
          !_vm.isWeixin ? _c("div", { staticClass: "layer_mask" }) : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "name" } }, [
      _vm._v("姓名"),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "name" } }, [
      _vm._v("电话"),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "name" } }, [
      _vm._v("公司"),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label", attrs: { for: "name" } }, [
      _vm._v("职位"),
      _c("span", { staticClass: "required" }, [_vm._v("*")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }