import { getCookie, deleteCookie } from "@/utils/utils";
import { ref } from "@vue/composition-api";
/**
 * 快照模式下的处理
 */
const useSnapshot = () => {
  // 判断是否显示快照模式
  const is_show_snapshot = ref(false);
  // 是否在快照模式下显示提示
  const is_show_snapshot_notice = ref(false);

  const snapshotInit = (app_id: string) => {
    const isShow = getCookie(`mpsnap-${app_id}`);
    if (isShow) {
      is_show_snapshot.value = true;
      is_show_snapshot_notice.value = true;
      deleteCookie(`mpsnap-${app_id}`);
    }
  };

  return {
    is_show_snapshot,
    is_show_snapshot_notice,
    snapshotInit
  };
};

export default useSnapshot;
