import HTTP from "@/utils/axios";
import { enterpriseHeaders } from "./headers";
const api = process.env.VUE_APP_API;

const Home = {
  // 获取表单 报名 页数据（基础数据+表格数据）
  getSmartFormPageData(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/smart_form/get_structure`, params, enterpriseHeaders);
  },
  // 获取智能表单地址
  getSmartFormRedirect(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/smart_form/get_redirect`, params);
  },
  // 提交智能表单
  setSmartFormSubmit(params: object, corp_id: string) {
    return HTTP.formPost(`${api}/client_v2/mobile/smart_form/submit?corp_id=${corp_id}`, params, enterpriseHeaders);
  },
  // 获取表单成功页
  getSmartFormSuccess(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/meeting/sign_up/get_page`, params);
  },
  // 模糊查找企业名
  getSearch_company(params: object) {
    return HTTP.get(`${api}/client/mobile/search_company`, params);
  },
  // 智能识别
  getSmartFormIntelligentRecognition(params: any, corp_id: string) {
    return HTTP.formPost(`${api}/client_v2/mobile/smart_form/intelligent_recognition?corp_id=${corp_id}`, params, enterpriseHeaders);
  },
};

export default {
  ...Home
};
