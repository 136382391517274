<template>
  <div
    v-if="check"
    class="home safe-area-inset-bottom"
    :style="{ pointerEvents: isWeixin ? '' : 'none' }"
  >
    <img src="@/assets/images/sign_up_page_01.png" alt="sign up page first part" />
    <div class="form_wrap">
      <div class="form_item">
        <label class="label" for="name">姓名<span class="required">*</span></label>
        <input class="text_input" type="text" placeholder="请输入姓名" v-model="user_name" />
      </div>
      <div class="form_item">
        <label class="label" for="name">电话<span class="required">*</span></label>
        <input
          class="text_input"
          type="text"
          placeholder="请输入电话"
          maxlength="11"
          v-model="mobile"
        />
      </div>
      <div class="form_item">
        <label class="label" for="name">公司<span class="required">*</span></label>
        <input class="text_input" type="text" placeholder="请输入公司名称" v-model="corp_name" />
      </div>
      <div class="form_item">
        <label class="label" for="name">职位<span class="required">*</span></label>
        <input class="text_input" type="text" placeholder="请输入职位信息" v-model="position" />
      </div>
      <button class="submit_button" @click="onSubmit">提交报名</button>
    </div>
    <img src="@/assets/images/sign_up_page_02.jpg" alt="sign up page second part" />
    <div v-if="on_error" class="error_message">{{ error_message }}</div>
    <div v-if="delayPop" class="error_message2">请使用个人微信<br />扫码报名</div>
    <div v-if="!isWeixin" class="layer_mask"></div>
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, watch } from "@vue/composition-api";
import Api from "@/api/api";
import useAuthcheck from "@/hooks/useAuthcheck";

export default defineComponent({
  name: "WillingSignUp",
  components: {},
  setup() {
    const state = reactive({
      // Auth: new Authorize(process.env.VUE_APP_ROOT),
      corp_id:
        process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
          ? "ww69faddedee10e02b"
          : "ww90b1ab33636e7da5",
      user_name: "",
      mobile: "",
      corp_name: "",
      position: "",
      check: false,
      keep_window: true, // 保持窗口打开状态
      on_error: false,
      error_message: "",
      ua: navigator.userAgent.toLowerCase(),
      isWXWork: false,
      isWeixin: false,
      delayPop: false
    });
    const Methods = {
      onSubmit: () => {
        if (state.user_name.length == 0) {
          state.on_error = true;
          state.error_message = "请输入姓名";
          setTimeout(() => {
            state.on_error = false;
            state.error_message = "";
          }, 2500);
        } else if (state.mobile.length == 0) {
          state.on_error = true;
          state.error_message = "请输入电话";
          setTimeout(() => {
            state.on_error = false;
            state.error_message = "";
          }, 2500);
        } else if (state.corp_name.length == 0) {
          state.on_error = true;
          state.error_message = "请输入公司名称";
          setTimeout(() => {
            state.on_error = false;
            state.error_message = "";
          }, 2500);
        } else if (state.position.length == 0) {
          state.on_error = true;
          state.error_message = "请输入职位信息";
          setTimeout(() => {
            state.on_error = false;
            state.error_message = "";
          }, 2500);
        } else {
          Api.postSubmitToApp({
            url: document.URL,
            wl_meeting_id: "666",
            user_name: state.user_name,
            mobile: state.mobile,
            corp_name: state.corp_name,
            position: state.position
          }).then(res => {
            if (res.code != 200) {
              state.on_error = true;
              state.error_message = "报名失败，请重试！";
              setTimeout(() => {
                state.on_error = false;
                state.error_message = "";
              }, 2500);
            } else {
              if (res.msg == "session failed") {
                state.on_error = true;
                state.error_message = "请授权后重试！";
                setTimeout(() => {
                  state.on_error = false;
                  state.error_message = "";
                }, 2500);
              } else {
                location.href = `${location.origin}/willing-sign-up-success`;
              }
            }
          });
        }
      }
    };
    onMounted(() => {
      useAuthcheck().then(res => {
        if (res === "uncheck") {
          state.check = false;
          state.keep_window = false;
        } else {
          state.check = true;
        }
      });
    });

    state.isWXWork = state.ua.match(/wxwork/i) == "wxwork";
    state.isWeixin = !state.isWXWork && state.ua.match(/micromessenger/i) == "micromessenger";

    if (!state.isWeixin) {
      setTimeout(() => {
        state.delayPop = true;
      }, 600);
    }

    // watch(
    //   state,
    //   () => {
    //     if (state.check == false && state.keep_window == false) {
    //       // eslint-disable-next-line
    //       if (window.WeixinJSBridge) {
    //         //这个可以关闭安卓系统的手机
    //         document.addEventListener(
    //           "WeixinJSBridgeReady",
    //           function() {
    //             // eslint-disable-next-line
    //             window.WeixinJSBridge.call("closeWindow");
    //           },
    //           false
    //         );
    //         //这个可以关闭ios系统的手机
    //         // eslint-disable-next-line
    //         window.WeixinJSBridge.call("closeWindow");
    //       }
    //     }
    //   },
    //   { immediate: true }
    // );

    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
</script>

<style lang="scss" scoped>
.safe-area-inset-bottom {
  padding-bottom: 0 !important;
  padding-bottom: constant(safe-area-inset-bottom) !important; // 兼容 IOS<11.2
  padding-bottom: env(safe-area-inset-bottom) !important; // 兼容 IOS>=11.2
}
.home {
  img {
    width: 100%;
    height: auto;
    vertical-align: middle;
  }
  .form_wrap {
    width: 90%;
    margin: 5%;
    display: flex;
    flex-direction: column;
    .form_item {
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      .label {
        width: 100%;
        height: 24px;
        margin: 8px 0px;
        display: flex;
        align-items: center;
        font-size: 16px;
        .required {
          color: rgb(255, 106, 83);
        }
      }
      .text_input {
        height: 48px;
        padding: 0px 0px 0px 5%;
        border: 1px solid rgb(238, 238, 238);
        border-radius: 10px;
        font-size: 16px;
      }
    }
    .submit_button {
      width: 100%;
      height: 54px;
      border-radius: 10px;
      border: 0px;
      background-image: linear-gradient(
        267.92deg,
        rgb(255, 47, 84) -2.33%,
        rgb(255, 134, 82) 99.35%
      );
      color: rgb(255, 255, 255);
      margin-top: 25px;
      font-size: 18px;
    }
  }
  .error_message {
    position: fixed;
    max-width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 18px;
    padding: 4%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    z-index: 10;
  }
  .error_message2 {
    position: fixed;
    max-width: 80%;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 18px;
    padding: 4%;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
    color: #ffffff;
    z-index: 100;
    text-align: center;
  }
  .layer_mask {
    position: fixed;
    top: 0;
    // pointer-events: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
  }
}
</style>
