








import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import useAppendForm from "@/hooks/useAppendForm";
export default defineComponent({
  name: "Home",
  components: {},
  setup() {
    const state = reactive<{
      content: string;
    }>({
      content: "Hello world"
    });
    onMounted(() => {
      useAppendForm();
    });
    return {
      ...toRefs(state)
    };
  }
});
