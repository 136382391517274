var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "safe-area-inset-bottom" }, [
    _c("img", {
      attrs: { src: require("@/assets/images/success_wxim.png"), alt: "" }
    }),
    _c("div", { staticClass: "wx-im-button-wrap", on: { click: _vm.openWx } }, [
      _c("div", { staticClass: "wx-im-button" }, [_vm._v("点击立即咨询")])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }