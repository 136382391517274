import HTTP from "@/utils/axios";
import { enterpriseHeaders } from "./headers";
const api = process.env.VUE_APP_API;

const Home = {
  // 获取会销 报名 页数据（基础数据+表格数据）
  getBusMtgSignPageData(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/meeting/sign_up/get_structure`, params, enterpriseHeaders);
  },
  // 会销 报名 表单提交
  postBusMtgFormData(params: object, corp_id: string) {
    return HTTP.formPost(`${api}/client_v2/mobile/meeting/sign_up/submit?corp_id=${corp_id}`, params, enterpriseHeaders);
  },
  // 获取会销 报名成功 页数据
  getBusMtgSignSuccessPageData(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/meeting/sign_up/get_page`, params);
  },
  // 获取会销 签到 页面数据
  getBusMtgCheckInPageData(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/meeting/check`, params, enterpriseHeaders);
  },

  // 获取表单 报名 页数据（基础数据+表格数据）
  getSmartFormPageData(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/smart_form/get_structure`, params, enterpriseHeaders);
  }
};

export default {
  ...Home
};
