









import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import useWxForm from "@/hooks/useWxForm";
export default defineComponent({
  name: "WillingADpageWithWxForm",
  components: {},
  setup() {
    const state = reactive<{
      content: string;
    }>({
      content: "Hello world"
    });
    onMounted(() => {
      useWxForm();
    });
    return {
      ...toRefs(state)
    };
  }
});
