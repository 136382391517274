














import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import useWxForm from "@/hooks/useWxForm";
import useWxIm from "@/hooks/useWxIm";
import { getQueryVariable } from "@/utils/utils";

export default defineComponent({
  name: "WillingADpageWithWxForm3",
  components: {},
  setup() {
    const state = reactive<{
      content: string;
      kf_id: string;
    }>({
      content: "Hello world",
      kf_id: getQueryVariable("kf_id") || ""
    });
    onMounted(() => {
      useWxForm();
      if (state.kf_id) {
        useWxIm(state.kf_id, true);
      }
    });
    return {
      ...toRefs(state)
    };
  }
});
