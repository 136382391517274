





























































import { defineComponent, reactive, SetupContext, toRefs } from "@vue/composition-api";
import { isInWeixin } from "@/utils/utils";
import Api from "@/api/api";
import useWebsiteTrack from "@/hooks/useWebsiteTrack";
export default defineComponent({
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{
      qr_code: any;
      url_scheme: any;
      isInWeixin: any;
      page_info: any;
    }>({
      qr_code: root.$route.query.qr_code,
      url_scheme: root.$route.query.url_scheme,
      isInWeixin: false,
      // isInWeixin: isInWeixin(),
      page_info: {}
    });
    const info = {
      submit_tip: "恭喜您, 报名成功！",
      guide_message: "添加导师微信立即领取开店资料, 还有金牌导师在线指导开店",
      qr_code: root.$route.query.qr_code,
      url_scheme: root.$route.query.url_scheme,
      state: "",
      title: "报名成功",
      state_view: false
    };
    const Methods = {
      onGoMiniprogrome() {
        window.location.href = state.page_info.url_scheme;
      },
      onGetInfo: async () => {
        try {
          const { data } = await Api.getPayPage({
            form_order_id: root.$route.query.wl_oid,
            type: 1
          });
          // state.page_info = data;
          state.page_info = Object.assign(info, data);
          document.title = state.page_info.title;
          // useWebsiteTrack.init(data.corp_id);
        } catch (err) {
          console.log(err, 99);
          state.page_info = info;
        }
      }
    };
    Methods.onGetInfo();
    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
