var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "safe-area-inset-bottom" },
    [
      _c("van-field", {
        attrs: { placeholder: "Client User ID" },
        model: {
          value: _vm.cUserId,
          callback: function($$v) {
            _vm.cUserId = $$v
          },
          expression: "cUserId"
        }
      }),
      _c("van-field", {
        attrs: { placeholder: "Phone" },
        model: {
          value: _vm.phone,
          callback: function($$v) {
            _vm.phone = $$v
          },
          expression: "phone"
        }
      }),
      _c("van-field", {
        attrs: { placeholder: "Email" },
        model: {
          value: _vm.email,
          callback: function($$v) {
            _vm.email = $$v
          },
          expression: "email"
        }
      }),
      _c("img", { attrs: { src: _vm.imgSrc, alt: "test" } }),
      _c(
        "div",
        { staticClass: "wx-im-button-wrap", on: { click: _vm.submit } },
        [_c("div", { staticClass: "wx-im-button" }, [_vm._v("点击立即咨询")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }