var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home safe-area-inset-bottom" }, [
      _c("img", {
        attrs: { src: require("@/assets/images/home1.png"), alt: "" }
      }),
      _c("img", {
        attrs: { src: require("@/assets/images/home2.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }