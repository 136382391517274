<template>
  <div>
    <div class="statement"
      >提交即表示阅读并同意<span class="statement_box" @click.stop="popupShow = true" 
        >《个人信息保护声明》</span
      ></div
    >
    <!-- 声明 -->
    <van-popup v-model="popupShow" round closeable  close-icon="close">
      <div class="popup_content">
        <h3 class="header" style="text-align: center;font-weight:700">个人信息保护声明</h3>
        <div class="popup_box">
          <div class="popup_item">
            <p>
              卫瓴·协同CRM提醒您，该声明由本页面创建者（即下文的“我们/公司”）向您提供，包含涉及您个人信息权益的重要约定，请您在提供个人信息前仔细阅读。如有问题，可联系本页面创建者。
            </p><br />
            <h3 class="bold">公司名称: {{companyName}}</h3>
            <p class="mt20">
              我们/公司非常重视用户信息的保护。您在使用我们的相关服务（以下简称“服务”），并在相应页面填写个人信息前，请您务必仔细阅读本声明。<span
                class="bold"
                >您一旦主动在页面提供您的个人信息并进行提交操作，即意味着您同意我们按照本声明收集、使用您在该页面提供的产品/服务涉及的必要信息。</span
              >
            </p>
            <h3 class="bold mt20">一、个人信息的收集</h3>
            <p>
              1、我们提供服务时，可能会收集下列与您有关的个人信息：<br />
              您在使用本页面所涉及的表单提供的信息，包括姓名、手机号及使用本页面提供的产品/服务涉及的必要信息。<br />
              <span class="bold"
                >请您注意，具体需要提供的信息可能会根据我们提供的产品/服务的不同而有所差异，请以向您展示的产品/服务以及所对应的要求提供相关个人信息，我们会在产品/服务页面或下文向您说明相关个人信息的收集和使用规则。</span
              ><br />
              2、您需要注意的是，以上个人信息均是您自愿提供。<span class="bold"
                >您有权拒绝提供，但可能无法正常使用相应的服务、功能或者无法达到服务拟达到的效果。</span
              >
            </p>
            <h3 class="bold mt20">二、个人信息的使用</h3>
            <p>
              1、您同意，我们可以通过以下方式对个人信息进行使用：<br />
              <span class="bold"
                >（1）我们收集您的姓名、手机号用于及时与您取得联系，并与您沟通产品或服务的相关事宜。</span
              ><br />
              <span class="bold"
                >（2）如您拟查询、修改、删除、撤回授权您提交的个人信息的，或对个人信息保护问题有任何疑问或投诉，您可以与我们联系，我们将尽快配合处理。</span
              ><br />
              2、我们将严格保护您的个人信息安全。我们将采用适当制度、组织和安全技术等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。<br />
              3、为了实现上述目的，我们可能会委托我们的技术合作伙伴（例如呼叫服务商）为您提供服务，并由其在服务范围内进行合法、正当、必要地使用。<br />
            </p>
            <h3 class="bold mt20">三、未成年人信息的特殊保护</h3>
            <p>
              1、若您是未满18周岁的未成年人，在使用本功能前，应在您的父母或其他监护人监护、指导下共同阅读并同意本隐私政策。<br />
              2、若您是未满14周岁的未成年人的监护人，在使用本功能前，应为您的被监护人阅读并同意本隐私政策。
            </p>
            <h3 class="bold mt20">四、适用法律</h3>
            <p>本声明适用中华人民共和国法律。</p>
            <h3 class="bold mt20">五、变更</h3>
            <p>
              我们会适时对本声明进行修订，该等修订构成本声明的一部分。公司会将修改后的声明予以发布。在该种情况下，若您继续使用我们的服务，即表示同意接受经修订的声明的约束。
            </p>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "@vue/composition-api";
export default {
  name: "",
  props: {
    // modelValue: {
    //   type: Boolean,
    //   default: false
    // }
    modelValue: Boolean,
    companyName: String,
  },
  setup(props, { emit }) {
    const state = reactive({
      statementChecked: false,
      popupShow: false
    });
    watch(
      () => props.checked,
      val => {
        state.statementChecked = val;
      }
    );
    watch(
      () => state.statementChecked,
      val => {
        emit("input", val);
      }
    );
    return {
      ...toRefs(state)
    };
  }
};
</script>

<style lang="scss" >
.statement {
  // margin: 0 4% j(20);
  text-align: center;
  .statement_box {
    color: #54b7ff;
    cursor: pointer;
  }
}
.van-popup__close-icon {
    position: absolute;
    z-index: 1;
    color: #c8c9cc;
    font-size: 16px;
    cursor: pointer;
}
.van-popup__close-icon--top-right {
    top: 9px;
    right: 9px;
}
.popup_content {
  // width: j(280);
  // height: j(458);
  .header {
    margin: j(15) 0;
  }
  .popup_box {
    width: j(280);
    height: j(420);
    overflow: scroll;
    font-size: j(14);
    margin-bottom: j(15);
    padding: 0 j(20);
    .popup_item {
      width: j(280);
      h3,
      p {
        line-height: j(24);
      }
      .bold {
        font-weight: 700;
      }
      .mt20 {
        margin-top: j(20);
      }
      .mt10 {
        margin-top: j(10);
      }
    }
  }
}
</style>
