import { getQueryVariable } from "@/utils/utils";

const config: any = {
  dev: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    src: "https://material.weiling.cn/h5/testjs/basic-form-A.min.js"
  },
  uat: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    src: "https://material.weiling.cn/h5/testjs/basic-form-A.min.js"
  },
  prd: {
    wl_id: "ww69faddedee10e02b", // corp_id
    wl_abbrev_name: "卫瓴广告落地页", // name
    src: "https://material.weiling.cn/h5/willing-third-party-js/basic-form-A.min.js"
  }
};

const useAppendForm = () => {
  let con = config[process.env.NODE_ENV as string];
  (window as any).WEILING_FEED = {
    DOMid: "willingTest",
    wl_src: getQueryVariable("wl_src"),
    wl_form_id: getQueryVariable("wl_form_id"),
    ...con
  };
  window.onload = function() {
    let wl = document.createElement("script");
    wl.src = `${con.src}?t=${Number(new Date())}`;
    wl.id = "wlForm";
    if (!document.getElementById("wlForm")) {
      let s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(wl, s);
    }
  };
};

export default useAppendForm;
