









































import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import Api from "@/api/businessMeeting";

export default defineComponent({
  name: "WillingSignUpSuccess",
  components: {},
  setup(props, context) {
    const state = reactive<{
      page_info: object;
      guide_text: string;
      weilingOnly: boolean;
    }>({
      page_info: {
        qr_code: {
          url: ""
        },
        submit_tip: {
          text: ""
        },
        guide_message: {
          text: "",
          css: {}
        }
      },
      guide_text: "",
      weilingOnly: false
    });
    const Methods = {
      applyHtmlStyle(content: string) {
        content = content
          .replace(/\n$/g, "\n\n")
          .replace(/[\r\n]/g, "<br>")
          .replace(/\s/g, "&nbsp;");
        return content;
      }
    };
    onMounted(() => {
      if (context.root.$route.query.corp_id == "ww69faddedee10e02b") {
        state.weilingOnly = true;
      }

      Api.getBusMtgSignSuccessPageData({
        corp_id: context.root.$route.query.corp_id,
        form_order_id: context.root.$route.query.order_id
      }).then(res => {
        state.page_info = res.data;
        state.guide_text = Methods.applyHtmlStyle(res.data.guide_message.text);
      });
    });
    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
