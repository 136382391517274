










import { defineComponent, onMounted, reactive, SetupContext, toRefs } from "@vue/composition-api";
import Api from "@/api/api";
import useWebsiteTrack from "@/hooks/useWebsiteTrack";

export default defineComponent({
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{
      imUrl: string;
    }>({
      imUrl: ""
    });
    const Methods = {
      openWx: () => {
        // location.href = "https://work.weixin.qq.com/kfid/kfc73e3c2aad055a587";
        location.href = state.imUrl;
      },
      getWximUrl: async () => {
        try {
          const { data } = await Api.getWximUrl({
            wl_kf_id: "1435483426790465536",
            corp_id: "ww90b1ab33636e7da5",
            url: window.location.href
          });
          state.imUrl = data.url;
          useWebsiteTrack.init("ww90b1ab33636e7da5")
        } catch (err) {
          console.log(err);
        }
      }
    };

    Methods.getWximUrl();
    return {
      ...Methods,
      ...toRefs(state)
    };
  }
});
