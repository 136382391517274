var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "home safe-area-inset-bottom",
      style: { backgroundColor: !_vm.checking ? "#0066fe" : "#fff" }
    },
    [
      !_vm.checking
        ? [
            _vm.page_info.guide_message && _vm.page_info.guide_message.text
              ? _c("div", { staticClass: "content" }, [
                  _vm.page_info.check_status === 0 ||
                  _vm.page_info.check_status === 2
                    ? _c("img", {
                        staticClass: "top_icon",
                        attrs: {
                          src: require("@/assets/images/businessMeeting/CheckIn/error_img.png"),
                          alt: "error"
                        }
                      })
                    : _vm._e(),
                  _vm.page_info.check_status === 1
                    ? _c("img", {
                        staticClass: "top_icon",
                        attrs: {
                          src: require("@/assets/images/businessMeeting/CheckIn/check_img.png"),
                          alt: "check"
                        }
                      })
                    : _vm._e(),
                  _vm.page_info.check_status === 0 ||
                  _vm.page_info.check_status === 2
                    ? _c("div", { staticClass: "title" }, [
                        _vm._v(" 很遗憾，签到失败 ")
                      ])
                    : _vm._e(),
                  _vm.page_info.check_status === 1
                    ? _c("div", {
                        staticClass: "title",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.page_info.submit_tip &&
                              _vm.page_info.submit_tip.text
                              ? _vm.page_info.submit_tip &&
                                  _vm.page_info.submit_tip.text
                              : "恭喜你，签到成功"
                          )
                        }
                      })
                    : _vm._e(),
                  _c("div", {
                    staticClass: "introduction",
                    domProps: {
                      innerHTML: _vm._s(
                        _vm.page_info.guide_message &&
                          _vm.page_info.guide_message.text
                      )
                    }
                  }),
                  _vm.page_info.qr_code && _vm.page_info.qr_code.url
                    ? _c("img", {
                        class: !_vm.isWeixin ? "qrcode cover_blur" : "qrcode",
                        attrs: {
                          src: _vm.page_info.qr_code.url,
                          alt: "qr code"
                        }
                      })
                    : _vm._e(),
                  _vm.page_info.qr_code && _vm.page_info.qr_code.url
                    ? _c("div", { staticClass: "guidance" }, [
                        _vm._v("长按识别图中二维码加好友")
                      ])
                    : _vm._e()
                ])
              : _c("div", { staticClass: "content_alter" }, [
                  _vm.isFailure || _vm.page_info.check_status === 2
                    ? _c("img", {
                        staticClass: "top_icon",
                        attrs: {
                          src: require("@/assets/images/businessMeeting/CheckIn/error_img.png"),
                          alt: "error"
                        }
                      })
                    : _vm._e(),
                  _vm.page_info.check_status === 1
                    ? _c("img", {
                        staticClass: "top_icon",
                        attrs: {
                          src: require("@/assets/images/businessMeeting/CheckIn/check_img.png"),
                          alt: "check"
                        }
                      })
                    : _vm._e(),
                  _vm.isFailure || _vm.page_info.check_status === 2
                    ? _c("div", { staticClass: "title" }, [
                        _vm._v(" 很遗憾，签到失败 ")
                      ])
                    : _vm._e(),
                  _vm.page_info.check_status === 1
                    ? _c("div", {
                        staticClass: "title",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.page_info.submit_tip &&
                              _vm.page_info.submit_tip.text
                              ? _vm.page_info.submit_tip &&
                                  _vm.page_info.submit_tip.text
                              : "恭喜你，签到成功"
                          )
                        }
                      })
                    : _vm._e()
                ]),
            _c("img", {
              staticClass: "bottom_img",
              attrs: {
                src: require("@/assets/images/businessMeeting/SignUpSuccess/no_qrcode_page_bottom.png"),
                alt: "bottom image"
              }
            }),
            _vm.delayPop
              ? _c("div", { staticClass: "error_message2" }, [
                  _vm._v("请使用个人微信"),
                  _c("br"),
                  _vm._v("扫码签到")
                ])
              : _vm._e(),
            !_vm.isWeixin ? _c("div", { staticClass: "layer_mask" }) : _vm._e()
          ]
        : [
            _c(
              "div",
              { staticClass: "loading-wrap" },
              [
                _c("van-loading", {
                  attrs: { color: "#0094ff", size: "24px", vertical: "" }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }