var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: [!_vm.isShow ? "loading" : "loading_none"] }, [
      _c(
        "div",
        { staticClass: "loading_wrap" },
        [
          _c("van-loading", { attrs: { type: "spinner", color: "#1C9FFF" } }),
          _c("span", { staticClass: "loading_text" }, [_vm._v("Loading")]),
          _c("span", { staticClass: "loading_dot" }, [_vm._v("...")])
        ],
        1
      )
    ]),
    _c("div", { class: [_vm.isShow ? "form_success" : "form_success_none"] }, [
      _vm.page_info.state_view
        ? _c("div", { staticClass: "qr_code_title" }, [
            _vm._v(_vm._s(_vm.page_info.title))
          ])
        : _vm._e(),
      _c("div", { staticClass: "add_mode_2" }, [
        _c("div", { staticClass: "qr_code" }, [
          _c("img", { attrs: { src: _vm.page_info.qr_code, alt: "" } })
        ])
      ]),
      _c("h3", { staticClass: "qr_code_text" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.isInWeixin
                ? "长按识别图中二维码加好友"
                : "截图保存二维码加好友"
            ) +
            " "
        )
      ]),
      _vm.page_info.guide_message
        ? _c("div", { staticClass: "guide_text" }, [
            _c("p", [_vm._v(_vm._s(_vm.page_info.guide_message))])
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }