<script>
import { getQueryVariable } from "@/utils/utils";
import Api from "@/api/api";
import HTTP from "@/utils/axios";
import { autoProtocol } from "@/utils/utils";

const wl_id = getQueryVariable("wl_id");
const wl_btn_id = getQueryVariable("wl_btn_id");
const wl_cl_fwd = getQueryVariable("wl_cl_fwd");

Api.getButtonClickUrl({
  button_id: wl_btn_id,
  corp_id: wl_id
}).then(res => {
  const data = res.data;
  console.log(data)
  if (wl_cl_fwd && wl_cl_fwd != "0") {
    HTTP.get("https://clue.weiling.cn/client/mobile/cl_fwd_att", {
      history_id: data.history_id,
      corp_id: data.corp_id
    }).then(() => {
      window.location = autoProtocol(data.wx_url);
    });
  } else {
    window.location = autoProtocol(data.wx_url);
  }
});
</script>
