var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.bgInfo
    ? _c(
        "div",
        {
          staticClass: "home safe-area-inset-bottom",
          style: _vm.getBgStyle(_vm.bgInfo)
        },
        [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "mask" }, [
              _c(
                "ul",
                _vm._l(_vm.pageSort, function(item) {
                  return _c(
                    "li",
                    { key: item },
                    [
                      item === "msg"
                        ? [
                            _vm.msg && _vm.msg.text
                              ? _c("div", { staticClass: "msg-wrap" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "title",
                                      style: {
                                        color: _vm.msg.css.font_color,
                                        background:
                                          _vm.msg.css.background_color,
                                        textAlign: _vm.msg.css.text_align
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.msg.text))]
                                  )
                                ])
                              : _vm._e()
                          ]
                        : item === "image"
                        ? [
                            _vm.qrCode
                              ? _c("div", { staticClass: "image-wrap" }, [
                                  _c("img", {
                                    staticClass: "qrcode-content",
                                    attrs: { src: _vm.qrCode, alt: "qr code" }
                                  })
                                ])
                              : _vm._e()
                          ]
                        : item === "attachments"
                        ? [
                            _vm.attachments && _vm.attachments.length
                              ? _c(
                                  "div",
                                  { staticClass: "attachments-wrap" },
                                  _vm._l(_vm.attachments, function(
                                    item,
                                    index
                                  ) {
                                    return _c(
                                      "a",
                                      {
                                        key: index,
                                        staticClass: "attr-item",
                                        attrs: { href: item[item.msgtype].url }
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "file-type",
                                          attrs: {
                                            src:
                                              item[item.msgtype].picurl ||
                                              item[item.msgtype].pic_url,
                                            alt: "pdf file"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "file-title" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    item[item.msgtype].title
                                                  )
                                                )
                                              ]
                                            ),
                                            _c("div", { staticClass: "desc" }, [
                                              _vm._v(
                                                _vm._s(item[item.msgtype].desc)
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }),
                0
              )
            ])
          ])
        ]
      )
    : _vm.page_info
    ? _c("div", { staticClass: "home-old safe-area-inset-bottom" }, [
        _c("div", { staticClass: "content-old" }, [
          _c("img", {
            staticClass: "top_icon",
            attrs: {
              src: require("@/assets/images/businessMeeting/SignUpSuccess/check_mark.png"),
              alt: "correct"
            }
          }),
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.page_info.submit_tip.text
                    ? _vm.page_info.submit_tip.text
                    : "恭喜你，提交成功"
                ) +
                " "
            )
          ]),
          _vm.guide_text
            ? _c("div", {
                staticClass: "introduction",
                domProps: { innerHTML: _vm._s(_vm.guide_text) }
              })
            : _vm._e(),
          _vm.page_info && _vm.page_info.qr_code && _vm.page_info.qr_code.url
            ? _c("img", {
                staticClass: "qrcode",
                attrs: { src: _vm.page_info.qr_code.url, alt: "qr code" }
              })
            : _vm._e()
        ]),
        _c("img", {
          staticClass: "bottom_img",
          attrs: {
            src: require("@/assets/images/businessMeeting/SignUpSuccess/qrcode_page_bottom.png"),
            alt: "bottom image"
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }