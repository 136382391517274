var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "home safe-area-inset-bottom" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/sign_up_success.png"),
          alt: "Sign Up Success"
        }
      }),
      _c("div", { staticClass: "title" }, [_vm._v("报名成功，请等待审核！")]),
      _c("div", { staticClass: "content" }, [
        _vm._v(
          " 感谢您的报名参与，我们将在1-2个工作日内完成审核。审核通过后，我们会安排专员与您联系，发送邀请函给您！ "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }