


























import { defineComponent, reactive, SetupContext, toRefs } from "@vue/composition-api";
import { isInWeixin } from "@/utils/utils";
import Api from "@/api/api";
import { Loading } from "vant";
import useWebsiteTrack from "@/hooks/useWebsiteTrack";
export default defineComponent({
  components: {
    Loading
  },
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{
      qr_code: any;
      url_scheme: any;
      isInWeixin: any;
      page_info: any;
      isShow: boolean;
    }>({
      qr_code: root.$route.query.qr_code,
      url_scheme: root.$route.query.url_scheme,
      isInWeixin: isInWeixin(),
      page_info: {},
      isShow: false
    });
    const info = {
      submit_tip: "",
      guide_message: "",
      qr_code: root.$route.query.qr_code,
      scheme: root.$route.query.url_scheme,
      state: "",
      title: "成功",
      state_view: false
    };
    const Methods = {
      onGetInfo: async () => {
        // if (root.$route.query.wl_clue_forword === "1") {
        try {
          const { data } = await Api.getOneClickUrl({
            corp_id: root.$route.query.wl_id,
            id: root.$route.query.wl_btn_id,
            click_id: root.$route.query.gdt_vid || root.$route.query.qz_gdt || '',
            wl_src: root.$route.query.wl_src || ''
          });

          if (root.$route.query.wl_cl_fwd === "1") {
            Api.checkIpv6({ history_id: data.history_id, corp_id: root.$route.query.wl_id });
          }

          // state.page_info = data;
          state.page_info = Object.assign(info, data);
          document.title = state.page_info.title;
          // location.href = data.scheme;
          // window.open(data.scheme, "_self");

          let a = document.createElement("a");
          a.setAttribute("href", data.scheme);
          a.setAttribute("target", "_self");
          a.setAttribute("id", "startTelMedicine");
          // // 防止反复添加
          // if (document.getElementById("startTelMedicine")) {
          //   document.body.removeChild(document.getElementById("startTelMedicine"));
          // }
          document.body.appendChild(a);
          a.click();

          setTimeout(() => {
            state.isShow = true;
          }, 3000);
          // useWebsiteTrack.init(data.corp_id);
        } catch (err) {
          console.log(err);
          state.page_info = info;
        }
      }
      // openTest: () => {
      //   window.open(state.page_info.scheme, "_self");
      // }
    };
    Methods.onGetInfo();
    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
