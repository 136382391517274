










import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
export default defineComponent({
  name: "WillingSignUpSuccess",
  components: {},
  setup() {
    const state = reactive<{
      // corp_id: string;
    }>({
      // corp_id:
      //   process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
      //     ? "ww69faddedee10e02b"
      //     : "ww90b1ab33636e7da5"
    });
    const Methods = {};
    // onMounted(() => {
    // });
    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
