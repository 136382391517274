













import { defineComponent, onMounted, reactive, SetupContext, toRefs } from "@vue/composition-api";
import Api from "@/api/api";
import useWebsiteTrack from "@/hooks/useWebsiteTrack";

export default defineComponent({
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{
      cUserId: string;
      phone: string;
      email: string;
      imgSrc: string;
      event_id: string;
    }>({
      cUserId: "",
      phone: "",
      email: "",
      imgSrc: "",
      event_id: ""
    });
    const Methods = {
      submit: async () => {
        try {
          const res = await Api.getRegisterQrcode({
            third_unique_user_id: state.cUserId,
            mobile: state.phone,
            email: state.email
          }, useWebsiteTrack.getEventId());
          state.imgSrc = res.data.live_code;
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (process.env.NODE_ENV == "prd") {
      useWebsiteTrack.init("ww69faddedee10e02b");
    } else {
      useWebsiteTrack.init("ww90b1ab33636e7da5");
    }

    return {
      ...Methods,
      ...toRefs(state)
    };
  }
});
