var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form_success" }, [
    _c("img", {
      staticClass: "success_icon",
      attrs: { src: require("@/assets/images/success_icon.png"), alt: "" }
    }),
    _c("h3", { staticClass: "success_text" }, [
      _vm._v(_vm._s(_vm.page_info.submit_tip || "恭喜您, 提交成功！"))
    ]),
    _c("div", { staticClass: "guide_text" }, [
      _c("p", [
        _vm._v(
          " " +
            _vm._s(
              _vm.isInWeixin ? "长按识别下方二维码" : "点击下方按钮或截图扫码"
            ) +
            " "
        )
      ]),
      _c("p", [_vm._v(_vm._s(_vm.page_info.guide_message))])
    ]),
    !_vm.isInWeixin
      ? _c("div", { staticClass: "add_mode_1" }, [
          _c("p", { staticClass: "add_mode_label" }, [
            _vm._v("添加好友方式一:")
          ]),
          _c(
            "div",
            {
              staticClass: "add_mode_btn",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.onGoMiniprogrome($event)
                }
              }
            },
            [_vm._m(0)]
          )
        ])
      : _vm._e(),
    !_vm.isInWeixin ? _c("div", { staticClass: "line" }) : _vm._e(),
    _c("div", { staticClass: "add_mode_2" }, [
      !_vm.isInWeixin
        ? _c("p", { staticClass: "add_mode_label label" }, [
            _vm._v("添加好友方式二:")
          ])
        : _vm._e(),
      _c("div", { staticClass: "qr_code" }, [
        _c("img", { attrs: { src: _vm.page_info.qr_code, alt: "" } }),
        _vm.page_info.state_view
          ? _c("p", [_vm._v(_vm._s(_vm.page_info.state))])
          : _vm._e()
      ])
    ]),
    _c("h3", { staticClass: "qr_code_text" }, [
      _vm._v(_vm._s(_vm.isInWeixin ? "长按识别二维码" : "截图保存二维码加好友"))
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "img_con" }, [
      _c("img", {
        staticClass: "add_btn",
        attrs: { src: require("@/assets/images/sub_btn.png"), alt: "" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }