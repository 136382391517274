import { getQueryVariable } from "@/utils/utils";

const config: any = {
  dev: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    wl_btn_id: "1418478097606533121",
    src: "https://demo.weiling.cn/h5/willing-third-party-js/wx-button.js"
  },
  uat: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    wl_btn_id: "1418478097606533121",
    src: "https://demo.weiling.cn/h5/willing-third-party-js/wx-button.js"
  },
  prd: {
    wl_id: "ww69faddedee10e02b", // corp_id
    wl_abbrev_name: "卫瓴广告落地页", // name
    src: "https://material.weiling.cn/h5/willing-third-party-js/wx-button.min.js"
  }
};

const useWxButton = () => {
  let con = config[process.env.NODE_ENV as string];
  (window as any).WEILING_FEED = {
    wl_src: getQueryVariable("wl_src"),
    ...con
  };
  window.onload = function() {
    let wl = document.createElement("script");
    wl.src = con.src;
    wl.id = "wlWxButton";
    if (!document.getElementById("wlWxButton")) {
      let s: any = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(wl, s);
    }
  };
};

export default useWxButton;
