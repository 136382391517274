<template>
  <div v-if="check && isWeixin" class="home safe-area-inset-bottom">
    <img src="@/assets/images/check_in.png" alt="Check In" />
    <div class="title">签到成功！</div>
    <div class="content">
      感谢您的到来，请前往会场参与企业微信线下闭门沙龙，签到处可领取伴手礼一份哦。
    </div>
  </div>
  <div v-else-if="!isWeixin" class="home1 safe-area-inset-bottom">
    <img src="@/assets/images/warning.png" alt="Check In" />
    <div class="warning_title">请使用个人微信扫码签到</div>
  </div>
  <div v-else></div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted, watch } from "@vue/composition-api";
import Api from "@/api/api";
import useAuthcheck from "@/hooks/useAuthcheck";

export default defineComponent({
  name: "WillingCheckIn",
  components: {},
  setup() {
    const state = reactive({
      check: false,
      keep_window: true, // 保持窗口打开状态
      ua: navigator.userAgent.toLowerCase(),
      isWXWork: false,
      isWeixin: false,
      corp_id:
        process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
          ? "ww69faddedee10e02b"
          : "ww90b1ab33636e7da5"
    });
    const Methods = {
      onCheckIn: () => {
        Api.getMeetingCheck({
          wl_meeting_id: "777",
          corp_id: state.corp_id
        });
      }
    };

    onMounted(() => {
      useAuthcheck().then(res => {
        if (res === "uncheck") {
          state.check = false;
          state.keep_window = false;
        } else {
          state.check = true;
          if (state.isWeixin) {
            Methods.onCheckIn();
          }
        }
      });
    });

    state.isWXWork = state.ua.match(/wxwork/i) == "wxwork";
    state.isWeixin = !state.isWXWork && state.ua.match(/micromessenger/i) == "micromessenger";

    // watch(
    //   state,
    //   () => {
    //     if (state.check == false && state.keep_window == false) {
    //       // eslint-disable-next-line
    //       if (window.WeixinJSBridge) {
    //         //这个可以关闭安卓系统的手机
    //         document.addEventListener(
    //           "WeixinJSBridgeReady",
    //           function() {
    //             // eslint-disable-next-line
    //             window.WeixinJSBridge.call("closeWindow");
    //           },
    //           false
    //         );
    //         //这个可以关闭ios系统的手机
    //         // eslint-disable-next-line
    //         window.WeixinJSBridge.call("closeWindow");
    //       }
    //     }
    //   },
    //   { immediate: true }
    // );

    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 38%;
    height: auto;
    margin-top: 130px;
    vertical-align: middle;
  }
  .title {
    width: 80%;
    font-size: 22px;
    font-weight: 600;
    margin-top: 45px;
    text-align: center;
  }
  .content {
    width: 80%;
    font-size: 16px;
    color: #666666;
    margin-top: 20px;
    line-height: 24px;
  }
}
.home1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40vh;
  img {
    width: 24px;
    height: 24px;
  }
  .warning_title {
    font-size: 21px;
    color: #666666;
    text-align: center;
    margin-left: 10px;
  }
}
</style>
