var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "snapshot-notice-wrap" }, [
    _c("div", { staticClass: "snapshot-notice", style: _vm.wrapStyle }, [
      _c("p", { staticClass: "text" }, [
        _vm._v("点击下方“使用完整服务”即可填写表单")
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }