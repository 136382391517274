











































































import { defineComponent, reactive, toRefs, onMounted } from "@vue/composition-api";
import useAuthcheck from "@/hooks/useAuthcheck";
import Api from "@/api/businessMeeting";

// import Vue from 'vue';
// import { Notify } from 'vant';
// Vue.use(Notify);
import { Notify } from 'vant';
import { getSignData } from "@/api/common";
import Vue from "vue";
export default defineComponent({
 
  name: "WillingSignUpSuccess",
  components: {},
  setup(props, context) {
    const state = reactive<{
      page_info: any;
      check: boolean;
      isWXWork: boolean;
      isWeixin: boolean;
      delayPop: boolean;
      ua: any;
      isFailure: boolean;
      checking: boolean
    }>({
      // page_info: {
      //   qr_code: {
      //     url: ""
      //   },
      //   submit_tip: {
      //     text: ""
      //   },
      //   guide_message: {
      //     text: "",
      //     css: {}
      //   },
      //   check_status: ""
      // },
      page_info: {},
      check: false,
      isWXWork: false,
      isWeixin: false,
      delayPop: false,
      ua: navigator.userAgent.toLowerCase(),
      isFailure: false,
      checking: true
    });
  // const vantData={
  //   show:false
  // }
    const Methods = { 
    //   showNotify() {
    //   vantData.show = true;
    //   setTimeout(() => {
    //   vantData.show = false;
    //   }, 2000);
    // },
    };

    const init = async () => {
      const signData = await getSignData();
      Vue.prototype.app_id = signData.app_id;

      Api.getBusMtgCheckInPageData({
        corp_id: context.root.$route.query.corp_id,
        wl_page_id: context.root.$route.query.wl_page_id
      }).then(res => {
        state.page_info = res.data;
        // state.page_info.check_status = 2;
        if (state.page_info.check_status === 2) {
          context.root.$notify({
            message: "已签到，请勿重复签到",
            color: "#494B4D",
            background: "#FFF5ED",
            duration: 5000,
            className:"test"
            // image:'@/assets/images/businessMeeting/CheckIn/message_img.png'
          });
          if(context.root.$route.query.wl_page_id === '1690984547736485888'){
            window.location.href= 'https://scrm-wx.weiling.cn/h5/monitorFile/index.html?corp_id=wpW2WmDAAALbZ1DzUgUUDncTFtI-1OWA&agent_id=1000003&material_id=1691342745148874752&source_type=corp&channel=worktableH5&share_type=1&event_id=1691342903856889856&rc_id='
            return
          }
          state.checking = false
        }else if (state.page_info.check_status === 0) {
          if(state.page_info.redirect_url){
            window.location.href=state.page_info.redirect_url
          }else{
            console.log('没有配置报名表');
            state.checking = false
            state.isFailure = true
          }
        }else{
          if(context.root.$route.query.wl_page_id === '1690984547736485888'){
            window.location.href= 'https://scrm-wx.weiling.cn/h5/monitorFile/index.html?corp_id=wpW2WmDAAALbZ1DzUgUUDncTFtI-1OWA&agent_id=1000003&material_id=1691342745148874752&source_type=corp&channel=worktableH5&share_type=1&event_id=1691342903856889856&rc_id='
            return
          }
          state.checking = false
        }
      });
    };

    onMounted(() => {
      useAuthcheck().then(res => {
        if (res === "uncheck") {
          state.check = false;
        } else {
          state.check = true;
          init();
        }
      });
    });

    state.isWXWork = state.ua.match(/wxwork/i) == "wxwork";
    state.isWeixin = !state.isWXWork && state.ua.match(/micromessenger/i) == "micromessenger";

    if (!state.isWeixin) {
      setTimeout(() => {
        state.delayPop = true;
      }, 200);
    }

    return {
      ...toRefs(state),
      // ...toRefs(vantData),
      ...Methods
    };
  }
});
