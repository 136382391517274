var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "home safe-area-inset-bottom" }, [
    _c("div", { staticClass: "content" }, [
      _c("img", {
        staticClass: "top_icon",
        attrs: {
          src: require("@/assets/images/businessMeeting/SignUpSuccess/check_mark.png"),
          alt: "correct"
        }
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.page_info.submit_tip.text
                ? _vm.page_info.submit_tip.text
                : "恭喜你，提交成功"
            ) +
            " "
        )
      ]),
      _c("div", {
        staticClass: "introduction",
        domProps: { innerHTML: _vm._s(_vm.guide_text) }
      }),
      _vm.page_info.qr_code.url
        ? _c("img", {
            staticClass: "qrcode",
            attrs: { src: _vm.page_info.qr_code.url, alt: "qr code" }
          })
        : _vm._e(),
      _vm.page_info.qr_code.url
        ? _c("div", { staticClass: "guidance" }, [
            _vm._v("长按识别图中二维码加好友")
          ])
        : _vm._e()
    ]),
    _vm.weilingOnly
      ? _c("div", { staticClass: "extra_content" }, [
          _c("video", {
            attrs: {
              src:
                "https://wlmvp-cos.weiling.cn/adlandingpage/businessMeeting/SignUpSuccess/weiling-intro-video.mp4",
              poster: require("@/assets/images/businessMeeting/SignUpSuccess/video_poster.png"),
              controls: ""
            }
          }),
          _vm._m(0)
        ])
      : _vm._e(),
    !_vm.weilingOnly
      ? _c("img", {
          staticClass: "bottom_img",
          attrs: {
            src: require("@/assets/images/businessMeeting/SignUpSuccess/qrcode_page_bottom.png"),
            alt: "bottom image"
          }
        })
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "extra_content_bottom" }, [
      _c("div", { staticClass: "extra_content_bottom_left" }, [
        _c("img", {
          attrs: {
            src: require("@/assets/images/weiling_logo.png"),
            alt: "weiling logo"
          }
        }),
        _c("span", [_vm._v("卫瓴·协同CRM")])
      ]),
      _c("div", { staticClass: "extra_content_bottom_right" }, [
        _c("span", [_vm._v("以客户为中心的")]),
        _c("span", { staticClass: "font_blue" }, [_vm._v("协同CRM")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }