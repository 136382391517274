

































import { defineComponent, reactive, SetupContext, toRefs } from "@vue/composition-api";
import { isInWeixin } from "@/utils/utils";
import Api from "@/api/api";
import useWebsiteTrack from "@/hooks/useWebsiteTrack";
export default defineComponent({
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{
      qr_code: any;
      url_scheme: any;
      isInWeixin: any;
      page_info: any;
    }>({
      qr_code: root.$route.query.qr_code,
      url_scheme: root.$route.query.url_scheme,
      isInWeixin: isInWeixin(),
      page_info: {}
    });
    const Methods = {
      onGetInfo: async () => {
        const { data } = await Api.getPayPage({
          form_order_id: root.$route.query.wl_oid,
          type: 2
        });
        state.page_info = data;
        document.title = data.title;
        // useWebsiteTrack.init(data.corp_id);
      },
      onGoMiniprogrome() {
        window.location.href = state.page_info.scheme;
      }
    };
    Methods.onGetInfo();
    return {
      ...toRefs(state),
      ...Methods
    };
  }
});
