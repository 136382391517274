import { getQueryVariable } from "@/utils/utils";

const config: any = {
  dev: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    wl_kf_id: ["1432244652996452352"],
    src: "https://demo.weiling.cn/h5/willing-third-party-js/v1/wxim-comp-v1.js"
  },
  uat: {
    istest: true,
    wl_id: "ww90b1ab33636e7da5", // corp_id
    wl_abbrev_name: "卫瓴广告落地页测试", // name
    wl_kf_id: ["1432244652996452352"],
    src: "https://demo.weiling.cn/h5/willing-third-party-js/v1/wxim-comp-v1.js"
  },
  prd: {
    wl_id: "ww69faddedee10e02b", // corp_id
    wl_abbrev_name: "卫瓴广告落地页", // name
    wl_kf_id: ["1436295311370633216"],
    src: "https://material.weiling.cn/h5/willing-third-party-js/v1/wxim-comp-v1.min.js"
  }
};

const useWxIm = (id: any, stop_track?: boolean) => {
  let con = config[process.env.NODE_ENV as string];
  (function() {
    let wl = document.createElement("script");
    wl.type = "text/javascript";
    wl.src = con.src;
    wl.id = "wlWXIM";
    wl.onload = function() {
      // @ts-ignore: Unreachable code error
      wlWximComp.init({
        ...con,
        wl_kf_id: id ? [id] : con.wl_kf_id,
        stop_track: stop_track || false
      });
    };
    if (!document.getElementById("wlWXIM")) {
      let s = document.getElementsByTagName("script")[0];
      // @ts-ignore: Unreachable code error

      s.parentNode.insertBefore(wl, s);
    }
  })();
};

export default useWxIm;
