const newDomain = process.env.VUE_APP_PROMO_DOMAIN!;

const currentURL = window.location.href;

window.location.href = newDomain + "/smart-form?" + currentURL.split("?")[1];

import Vue from "vue";
Vue.extend({
  template: ""
});
