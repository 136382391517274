








import { computed, defineComponent } from "@vue/composition-api";
import { snapshot_notice_bg } from "@/utils/cdn";
export default defineComponent({
  name: "SnapshotNotice",
  setup() {
    const wrapStyle = computed(() => {
      return {
        "background-image": `url(${snapshot_notice_bg})`
      };
    });

    return {
      wrapStyle
    };
  }
});
