








import { defineComponent, reactive, SetupContext, toRefs } from "@vue/composition-api";
export default defineComponent({
  setup(props: any, { root }: SetupContext) {
    const state = reactive<{}>({});
    return {
      ...toRefs(state)
    };
  }
});
